body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bookmania-black';
  src: local('Bookmania-black'),
    url('./fonts/Bookmania-black.otf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'Proxima-Nova';
  src: url('./fonts/Proxima\ Nova.otf');
}
