body {
  font-family: 'Proxima-Nova';
}
.App {
  text-align: center;
}
.bookmania {
  font-family: 'Bookmania';
}
.bookmania-bold {
  font-family: 'Bookmania-black';
}
.proxima-nova {
  font-family: 'Proxima-Nova';
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-center-bottom {
  background-position: left bottom;
}
.scale input[type='radio' i]:checked ~ label {
  border: 4px solid #130049;
}
.grade-text {
  background: linear-gradient(239.2deg, #fe4254 31.33%, #fbcd41 129.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.h-calc-enrolment {
  height: calc(100vh - 48px);
}
.h-calc-82 {
  height: calc(100vh - 78px);
}
.right-side-pic {
  background: url('../public/images/start.png') no-repeat right;
  background-size: contain;
  height: 100vh;
  width: 100%;
  min-height: 650px;
}
.return-ride-side-pic {
  background: url('../public/images/return_customer_right.png') no-repeat right;
  background-size: contain;
  height: 100vh;
  width: 100%;
  min-height: 650px;
}

.return-customer-bg {
  background: url('../public/images/return_customer_bg.png');
  background-size: cover;
  background-position: center right;
}
.enrolment-bg {
  background: url('../public/images/start-enrolment_bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 100vh;
}
.success-right {
  background-image: url('../public/images/success_bg.png');
  background-size: contain;
  height: 100vh;
  width: 100%;
  min-height: 650px;
  background-position: right;
  background-repeat: no-repeat;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.-z-1 {
  z-index: -1;
}
input[type='radio' i] {
  accent-color: #130049;
}

input[type='checkbox' i]:checked {
  background-image: url('../public/images/check_tick.svg');
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid #130049;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.-z-1 {
  z-index: -1;
}
.border-50 {
  border-radius: 50%;
}
.origin-0 {
  transform-origin: 0%;
}
.appearance-none {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}
.menu-transition {
  transform-origin: top center;
}

input:focus ~ label,
select:focus ~ label {
  --tw-text-opacity: 1;
  left: 0px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.react-tel-input .form-control {
  width: 100%;
  padding: 14.5px 14px 14.5px 60px;
}
.rc-time-picker-input {
  height: 55px;
  padding-top: 20px;
  padding-left: 15px;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: #000;
  font-size: 16px;
}

.rc-time-picker-clear {
  display: none;
}
.loader1 {
  background: linear-gradient(270deg, #fe4254 0%, #fbcd41 100%);
}
.spinner-container {
  background: url('./../public/images/loader_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.loader {
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 10rem;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #fe4254 0deg,
    #fe4254 63.24deg,
    #fddf85 217.5deg,
    rgba(255, 255, 255, 0) 360deg
  );
  animation: animate 1s linear infinite;
}

.loader::before {
  position: absolute;
  content: '';
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-radius: 10rem;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.payment-bg {
  background-image: url('../public/images/payment_bg.png');
  background-size: cover;
  background-position: center;
}
.payment-success-bg {
  background: url('../public/images/payment_success-right.png') no-repeat right;
  background-size: cover;
  height: 100vh;
  width: 100%;
  min-height: 650px;
}
.child-taken {
  background: url('../public/images/podlist_bg.png') no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1024px) {
  .enrolment-bg {
    background: url('../public/images/enrollment-mobile.png') no-repeat bottom
      center;
    background-size: contain;
    width: 100vw;
  }

  .h-calc-enrolment {
    height: auto;
  }
  .success-bg {
    background-image: url('../public/images/success-mobile.png');
    background-size: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  .payment-success-bg {
    background: none;
  }
  .bg-center-bottom {
    background-position: center bottom;
  }
  .return-customer-bg {
    height: auto;
  }
  .return-mobile-bg {
    background: url('../public/images/return_customer_mobile.png') no-repeat
      bottom;
    background-size: cover;
    height: calc(100vh - 225px);
    width: 100%;
  }
  .mobile-font {
    font-size: 18px !important;
    padding-left: 40px;
  }
}
